import { createSlice } from "@reduxjs/toolkit";
export const RawDataSlice = createSlice({
  name: "raw_data",
  initialState: {
    value: [],
  },
  reducers: {
    populate_raw_data: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_raw_data } = RawDataSlice.actions;
export default RawDataSlice.reducer;

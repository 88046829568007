import React, { useEffect } from "react";
import tw from "twin.macro";
import Accounting from "../../Images/Accounting.png";
import Marketing from "../../Images/Marketing.png";
import Design from "../../Images/Design.png";
import AutomotiveJobs from "../../Images/AutomotiveJobs.png";
import axios from 'axios';
const Container = tw.div`relative w-full pt-20 `;
const CountDescriptionContainer = tw.div`flex-col `;

const CountContainer = tw.div` text-3xl  font-bold`;

const DescriptionContainer = tw.div` text-xl font-semibold`;

const RightContainer = tw.div` flex-col md:pl-6`;

const CardContianer = tw.div`w-full   flex flex-col flex-wrap items-center md:items-stretch md:flex-row  md:justify-center`;

const Card = tw.div`flex w-48 md:w-twentypercent flex-col sm:flex-row items-center m-6 bg-white  text-center sm:text-left md:px-6 py-6 border-solid border rounded-xl`;
const Heading = tw.div`text-xl ml-20 text-gray-500 font-semibold`;

export default ({
  cards = null,
  heading = "APPLICATION STATISTICS",
  subheading = "2020 jobs live - 293 added today. ",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
}) => {
  const defaultCards = [
    {
      imageSrc: Accounting,
      title: "29",
      description: "Customers",
    },
    {
      imageSrc: Marketing,
      title: "3",
      description: "Categories",
    },
    {
      imageSrc: Design,
      title: "8",
      description: "Services",
    },

    {
      imageSrc: AutomotiveJobs,
      title: "2",
      description: "Providers",
    },
    {
      imageSrc: Accounting,
      title: "0",
      description: "Advertisements",
    },
    {
      imageSrc: Marketing,
      title: "28",
      description: "Posts",
    },

    {
      imageSrc: Design,
      title: "0",
      description: "Comments",
    },

    {
      imageSrc: AutomotiveJobs,
      title: "0",
      description: "Orders",
    },
  ];

  return (
    <Container>
      <Heading>Quick Stats</Heading>
      <CardContianer>
        <Card>
          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Total Vending</DescriptionContainer>
              <CountContainer>28,345</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>

        <Card>
          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Total Location</DescriptionContainer>
              <CountContainer>120</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>

        <Card>
          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Total Zone</DescriptionContainer>
              <CountContainer>89</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>

        <Card>
          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Products</DescriptionContainer>
              <CountContainer>46%</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>
      </CardContianer>
    </Container>
  );
};

import { configureStore } from "@reduxjs/toolkit";
import ComponentReducer from "./ComponentSlice";
import CardsReducer from "./CardsSlice";
import TableReducer from "./TableSlice";
import AppReducer from "./AppSlice";
import ZoneReducer from "./ZonesSlice";
import RawDataReducer from "./RawDataSlice";
import NFCLogReducer from "./NFCLogSlice";
import VendingMachineReducer from "./VendingMachineSlice";
import UserReducer from "./UserSlice";
export const store = configureStore({
  reducer: {
    callComponent: ComponentReducer,
    CardsComponent: CardsReducer,
    TableComponent: TableReducer,
    AppComponent: AppReducer,
    callZone: ZoneReducer,
    callVendingMachine: VendingMachineReducer,
    callRawData: RawDataReducer,
    callNFCLog: NFCLogReducer,
    callUser:UserReducer
  },
});

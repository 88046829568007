/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { populate_vending_machine } from "../Redux/VendingMachineSlice";
import { componentReducer } from "../Redux/ComponentSlice.js";
const Container = tw.div`relative w-full mx-6`;
const FlexContainer = tw.div`flex items-center w-full bg-red-500`;
const FormContainer = tw.div` bg-white  md:mx-16 rounded-xl p-4 md:p-8 mb-8`;
const InputContainer = tw.div`relative flex items-center py-5 w-full`;
const Label = tw.div`text-gray-900 tracking-wide font-semibold   text-base md:text-2xl w-4/12 md:w-3/12`;
const Input = tw.input`p-2 md:p-4 rounded-lg bg-gray-200 w-6/12 md:w-6/12`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-end `;
const ActionsMain = tw.div`flex flex-col sm:flex-row justify-center mt-8  lg:justify-center `;
const Link1 = tw.a`bg-blue-200 text-blue-900 px-6 py-2 rounded-lg  text-base md:text-2xl font-semibold`;
const Link2 = tw.button`bg-blue-200 text-blue-900 mx-4 my-2 px-6 py-2 rounded-lg  text-base md:text-2xl font-semibold`;
const FormButton = tw.a`bg-blue-200 text-blue-900 ml-4 px-6 py-2 rounded-lg text-base md:text-2xl font-semibold`;
const Heading = tw.div`text-2xl md:text-3xl font-bold pb-14`;
axios.defaults.withCredentials = true;

export default () => {
  const dispatch = useDispatch();
  const vending_machines = useSelector(
    (state) => state.callVendingMachine.value
  );
  // const [show_upload_modal, set_show_upload_modal] = useState(false);
  const [name, set_name] = useState("");
  const onChangeName = (e) => {
    set_name(e.target.value);
  };
  const [qr_id, set_qr_id] = useState("");
  const onChangeQrId = (e) => {
    set_qr_id(e.target.value);
  };
  const [product_price, set_product_price] = useState(0);
  const onChangeProductPrice = (e) => {
    set_product_price(e.target.value);
  };
  const [responseData, setResponseData] = useState({});
  const AddVendingMachine = (e) => {
    e.preventDefault();
    if (name.length > 0 && qr_id.length > 0) {
      const data = JSON.stringify({
        displayName: name,
        productPrice: product_price,
        razorpayQrCodeId: qr_id,
      });
      console.log("checking")
      axios
        .post(`https://web.dotconn.net/api/instagood/vending_machines`, data, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("The response result", JSON.stringify(response.data));
          setResponseData(response.data);
          console.log("The response parsed result", response.data);
          // alert("Successfully Added Vending Machine!!");

          let temp_vending_machine = [];
          for (let i = 0; i < vending_machines.length; i++) {
            temp_vending_machine.push(vending_machines[i]);
          }
          temp_vending_machine.push({
            vendingMachineId: JSON.stringify(response.data).vendingMachineId,
            displayName: name,
            productPrice: product_price,
            productAvailable: 0,
            razorpayQrCodeId: qr_id,
            lastCommunicatedTs:
              new Date().getFullYear() +
              "-" +
              Number(new Date().getMonth() + 1) +
              "-" +
              new Date().getDate() +
              " " +
              new Date().getHours() +
              ":" +
              new Date().getMinutes() +
              ":" +
              new Date().getSeconds(),
          });
          dispatch(populate_vending_machine(temp_vending_machine));
          set_showForm(true)
          ResetVendingMachine();
          // dispatch(componentReducer(2));
        })
        .catch((error) => {
          console.log(error);
          alert(error.message);
        });
    } else {
      alert("Fields are missing!!");
    }
  };
  const ResetVendingMachine = () => {
    set_name("");
    set_qr_id("");
    set_product_price(0);
  };
  // const downloadInformation = (e) => {
  //   e.preventDefault();
  //   alert("File is being download")
  // }
  const downloadInformation = e => {
    e.preventDefault();
    // alert("File is downloading...")
    let newStr = JSON.stringify(responseData);
    // newStr = newStr.replace("{", "");
    // newStr = newStr.replace("}", "");
    // newStr = newStr.replace(/["]/g, "");
    // newStr = newStr.replace(/[,]/g, "\n");

    const element = document.createElement("a");
    const file = new Blob([newStr],
      { type: 'application/json;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    element.download = responseData.displayName;
    document.body.appendChild(element);
    element.click();
  }
  const [showForm, set_showForm] = useState(false)
  return (
    <Container>
      {showForm === false ?
        (<FormContainer>
          <Heading>ADD VENDING</Heading>
          <form action="#">
            <InputContainer>
              <Label htmlFor="name-input">Vending Machine</Label>
              <Input
                id="full-name"
                type="text"
                name="fullname"
                value={name}
                onChange={(e) => onChangeName(e)}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="name-input">QR Code Id</Label>

              <Input
                id="Qrid"
                type="text"
                name="Qrid"
                value={qr_id}
                onChange={(e) => onChangeQrId(e)}
              />
              {/* <FormButton href="#">Add</FormButton> */}
            </InputContainer>
            <InputContainer>
              <Label htmlFor="name-input">Product Price</Label>

              <Input
                id="productprice"
                type="number"
                pattern="[0-9]*"
                name="productprice"
                value={product_price}
                onChange={(e) => onChangeProductPrice(e)}
              />
              {/* <FormButton href="#">Add</FormButton> */}
            </InputContainer>
            <ActionsMain>

              <Link2
                // href="#"
                // onClick={() => set_show_upload_modal(true)}
                onClick={(e) => AddVendingMachine(e)}
              >
                Add
              </Link2>
              {/* <button>
              Add
            </button> */}
              <Link2
                //href="#"
                onClick={() => ResetVendingMachine()}
              >
                Reset
              </Link2>
              <Link2
                //href="#"
                onClick={() => dispatch(componentReducer(2))}
              >
                Cancel
              </Link2>
            </ActionsMain>
          </form>
        </FormContainer>)
        :
        (
          <FormContainer>
            <Heading>Vending Details</Heading>
            <table style={{
              borderCollapse: "collapse",
              width: '100%',
              fontSize: "1rem"
            }}>
              <thead>
                <tr style={{
                }}>
                  <th style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>Name</th>
                  <th style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{
                }}>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>Vending Machine Id</td>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>{responseData.vendingMachineId}</td>
                </tr>

                <tr style={{
                }}>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>Machine Name</td>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>{responseData.displayName}</td>
                </tr>
                <tr style={{
                }}>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>Razorpay QR Code ID</td>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>{responseData.razorpayQrCodeId}</td>
                </tr>
                <tr style={{
                }}>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>Product Price</td>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>{responseData.productPrice}</td>
                </tr>
                <tr style={{
                }}>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>MQTT Port</td>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>{responseData.mqttUrlPort}</td>
                </tr>
                <tr style={{
                }}>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>MQTT Host:mqtt</td>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>{responseData.mqttUrlHost}</td>
                </tr>
                <tr style={{
                }}>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>MQTT Topic To Publish</td>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>{responseData.mqttTopicToPublish}</td>
                </tr>
                <tr style={{
                }}>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>MQTT Topic To Subscribe</td>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>{responseData.mqttTopicToSubscribe}</td>
                </tr>

                <tr style={{
                }}>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>MQTT Username</td>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>{responseData.mqttUsername}</td>
                </tr>
                <tr style={{
                }}>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>MQTT Password</td>
                  <td style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px"
                  }}>{responseData.mqttPassword}</td>
                </tr>
              </tbody>

            </table>
            <ActionsMain>
              <Link2
                // href="#"
                // onClick={() => set_show_upload_modal(true)}
                onClick={(e) => downloadInformation(e)}
              >
                Download
              </Link2>
              {/* <button>
              Add
            </button> */}
              <Link2
                //href="#"
                onClick={() => dispatch(componentReducer(2))}
              >
                Cancel
              </Link2>
            </ActionsMain>

          </FormContainer>
        )}

      {/* {show_upload_modal === true ? (
        <FormContainer>
          <Heading>ADD VENDING</Heading>
          <form action="#">
            <InputContainer>
              <Label htmlFor="name-input">Vending Machine</Label>
              <Input id="full-name" type="text" name="fullname" />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="name-input">Name</Label>
              <Input id="company-name" type="text" name="companyname" />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="name-input">QR Code Id</Label>

              <Input id="phonenumber" type="text" name="phonenumber" />
              <FormButton href="#">Add</FormButton>
            </InputContainer>

            <InputContainer>
              <Label htmlFor="name-input">MQTT</Label>
              <Input id="category" type="text" name="category" />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="name-input">User Name</Label>
              <Input
                id="minimum-service-charge"
                type="text"
                name="minimumservicecharge"
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="name-input">Password</Label>
              <Input
                id="minimum-service-charge"
                type="password"
                name="minimumservicecharge"
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="name-input">Publish Topic</Label>
              <Input
                id="minimum-service-charge"
                type="text"
                name="minimumservicecharge"
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="name-input">Subscribe Topic</Label>
              <Input
                id="minimum-service-charge"
                type="text"
                name="minimumservicecharge"
              />
              <FormButton href="#">Add</FormButton>
            </InputContainer>

            <Actions>
              <Link1 href="#">Create</Link1>
            </Actions>
          </form>
        </FormContainer>
      ) : null} */}
    </Container>
  );
};

import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import Planextlogo from "../Images/logo.png";
import SignoutIcon from "../Images/signout.png";
import SearchSmall from "./SearchSmall";
import {useNavigate} from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { AppReducer } from "./Redux/AppSlice.js";
const Container = tw.div`bg-gray-200 items-center w-full justify-between flex py-4 md:px-20 px-6 `;
const UserContainer = tw.div`mx-2 md:mx-8`;
const FlexContainer = tw.div`flex items-center`;

const NavButton = tw.div`md:mx-6 my-4`;
const HeaderLogoContainer = tw.div``;

const Subheading = tw.div`text-sm md:text-base`;
const Heading = tw.div`text-base md:text-2xl font-semibold`;

export default () => {
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const number = useSelector((state) => state.callComponent.value);
  const user_details = useSelector((state) => state.callUser.value);
  const SignoutFunction=()=>
  {
    navigate("/")
  }
  return (
    <Container>
      <HeaderLogoContainer>
        <img src={Planextlogo} width="100%" />
      </HeaderLogoContainer>

      {number === 1 ? (
        <FlexContainer>
          <SearchSmall />
          <UserContainer>
            <Subheading>Hello,</Subheading>
            <Heading>{user_details.user?.displayName}</Heading>
          </UserContainer>
          <img style={{cursor:"pointer"}}src={SignoutIcon} onClick={() => SignoutFunction()} />
        </FlexContainer>
      ) : (
        <FlexContainer>
          <UserContainer>
            <Subheading>Hello,</Subheading>
            <Heading>{user_details.user?.displayName}</Heading>
          </UserContainer>
          <img style={{cursor:"pointer"}}src={SignoutIcon} onClick={() => SignoutFunction() } />
        </FlexContainer>
      )}
    </Container>
  );
};

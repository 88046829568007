/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { PrimaryButton, Link2 } from "./MiscStyles/Buttons";

import { useSelector, useDispatch } from "react-redux";

import Dashboard from "./ContentPage/Dashboard";
import RawData from "./ContentPage/RawData";
import Zone from "./ContentPage/Zone";
import NfcLog from "./ContentPage/NfcLog";
import VpiLog from "./ContentPage/VpiLog";
import VendorsTable from "./ContentPage/VendorsTable";
import SignIn from "./SignIn";
import AddVending from "./ContentPage/AddVending";
import Navbar from "./Navbar";
import { populate_zones } from "./Redux/ZonesSlice";
import { populate_vending_machine } from "./Redux/VendingMachineSlice";
import axios from "axios";
const Container = tw.div`relative w-9/12 md:w-10/12`;
const ContentContainer = tw.div` flex justify-center  bg-gray-200`;
// const Container = tw.div`w-10/12 flex justify-center `;
const Content = tw.div`fixed right-0 w-10/12 `;
const TestDisplayArea = tw.div`py-40 bg-gray-600`;
const Text = tw.p`text-blue-700 font-bold text-2xl text-center`;

export default () => {
  const number = useSelector((state) => state.callComponent.value);
  const dispatch = useDispatch();
  //Fetch All The Zones
  useEffect(() => {
    axios
      .get("https://web.dotconn.net/auth/compartments", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        dispatch(populate_zones(response.data));
      })
      .catch((error) => {
        alert(error.meessage);
      });
  }, []);
  //Fetch All The Vending Machines
  // useEffect(() => {
  //   axios
  //     .get(`https://web.dotconn.net/api/instagood/vending_machines`, {
  //       withCredentials: true,
  //     })
  //     .then((response) => {
  //       console.log(response.data);
  //       dispatch(populate_vending_machine(response.data));
  //     })
  //     .catch((error) => {
  //       alert(error.meessage);
  //     });
  // }, []);
  return (
    <Container>
      <Navbar />
      <ContentContainer>
        {number === 1 ? <Dashboard /> : null}
        {number === 2 ? <Zone /> : null}
        {number === 3 ? <AddVending /> : null}
        {/* {number === 4 ? <RawData /> : null}
        {number === 5 ? <NfcLog /> : null}
        {number === 6 ? <VpiLog /> : null}
        {number === 7 ? <VendorsTable /> : null}
        {number === 8 ? <Text>ADVERTISEMENTS AREA</Text> : null} */}
      </ContentContainer>
    </Container>
  );
};

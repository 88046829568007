import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import "../Components/style.css";
import vendingmachine from "../Images/Video1.png";
import HeaderLogo from "../Images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { AppReducer } from "./Redux/AppSlice";
import WideNavBar from "./WideNavBar";
import { populate_user } from "./Redux/UserSlice";
import {useNavigate} from 'react-router-dom';
import axios from "axios";
const SignInContainer = tw.div`relative bg-white`;
const Container = tw.div`w-full  my-20 `;
const FlexContainer = tw.div`md:flex justify-center md:mx-64`;
const LeftContainer = tw.div`md:w-1/2 mx-10 flex justify-center border-2 border-black  bg-white`;
const RightContainer = tw.div`md:w-1/2 mx-10 py-20 flex justify-center bg-gray-200`;

const FormContainer = tw.div` w-full `;
const LogoContainer = tw.div`flex justify-center`;
const InputsContainer = tw.div`w-full `;
const InputFlexContainer = tw.div`flex mt-10 justify-center`;
const ButtonContainer = tw.div`my-10 flex justify-center text-xl`;

const InputContainer = tw.input`p-4 w-9/12 text-xl bg-white`;
const ForgetPassword = tw.div`flex  my-6 justify-end mr-24 cursor-pointer`;

export default () => {
  const navigate=useNavigate();
  const number = useSelector((state) => state.AppComponent.value);
  const dispatch = useDispatch();
  const [user_email, set_user_email] = useState("");
  const onChangeUsername = (e) => {
    set_user_email(e.target.value);
  };
  const [user_password, set_user_password] = useState("");
  const onChangeUserpassword = (e) => {
    set_user_password(e.target.value);
  };
  const sign_in = () => {
    let data = JSON.stringify({
      username: user_email,
      password: user_password,
    });
    axios
      .post("https://web.dotconn.net/auth/authenticate", data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        navigate("/dashboard");
       // dispatch(AppReducer(2));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <SignInContainer>
      <WideNavBar />
      <Container>
        <FlexContainer>
          <LeftContainer>
            <img src={vendingmachine} width="55%" class="loginImage" />
          </LeftContainer>
          <RightContainer>
            <FormContainer>
              <LogoContainer>
                <img src={HeaderLogo} width="40%" />
              </LogoContainer>

              <InputsContainer>
                <InputFlexContainer>
                  <InputContainer
                    value={user_email}
                    onChange={(e) => onChangeUsername(e)}
                    type="text"
                    placeholder="Email Address"
                  />
                </InputFlexContainer>
                <InputFlexContainer>
                  <InputContainer
                    value={user_password}
                    onChange={(e) => onChangeUserpassword(e)}
                    type="password"
                    placeholder="Password"
                  />
                </InputFlexContainer>
                <ForgetPassword onClick={() => console.log("Test")}>
                  forget password?
                </ForgetPassword>
                <ButtonContainer>
                  <button class="button button--pandora">
                    <span onClick={() => sign_in()}>Sign In</span>
                  </button>
                </ButtonContainer>
              </InputsContainer>
            </FormContainer>
          </RightContainer>
        </FlexContainer>
      </Container>
    </SignInContainer>
  );
};

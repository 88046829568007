/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { useSelector, useDispatch } from "react-redux";
import PreviousIcon from "../../Images/BackIcon.png";
import NextIcon from "../../Images/NextIcon.png";
import CloseIcon from "../../Images/Close.png";
import axios from "axios";
import { populate_raw_data } from "../Redux/RawDataSlice.js";
const Container = tw.div`relative w-full my-10`;

const TableContainer = tw.div`  bg-white  items-center md:items-stretch  md:justify-center overflow-hidden border-2 border-blue-300 rounded-xl  `;

const TableTop = tw.div`flex flex-row items-center justify-center  text-center py-2 border-solid border-b  border-blue-300  `;

const Card = tw.div`flex flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-6 border-solid border-b   border-blue-300`;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center  text-xs md:text-base`;
const TableDetailsButton = tw.button`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center  text-xs md:text-base`;

const TableTitle = tw.div`relative py-3 text-xs md:text-base`;

const Column = tw.div``;
export default ({ cards = null, props }) => {
  const raw_data = useSelector((state) => state.callRawData.value);
  const dispatch = useDispatch();
  const [show_message, set_show_message] = useState(false);
  const [command, set_command] = useState("");
  const [device_id, set_device_id] = useState("");
  const [date, set_date] = useState("");
  const [product_available, set_product_available] = useState("");
  const ShowFullMessage = (_raw_msg) => {
    set_show_message(true);
    set_command(JSON.parse(_raw_msg).command);
    set_device_id(JSON.parse(_raw_msg).device_id);
    set_product_available(JSON.parse(_raw_msg).product_available);
    set_date(JSON.parse(_raw_msg).Date);
  };
  const [offset, set_offset] = useState(0);
  const [raw_data_array, set_raw_data_array] = useState([]);
  useEffect(() => {
    let temp_raw_data_array = [];
    if (raw_data.length < 10) {
      for (let i = 0; i < raw_data.length; i++) {
        temp_raw_data_array.push(raw_data[i]);
      }
    } else {
      for (let i = 0; i < 10; i++) {
        temp_raw_data_array.push(raw_data[i]);
      }
    }
    set_raw_data_array(temp_raw_data_array);
  }, []);
  const onPressNext = () => {
    set_offset(Number(offset + 10));
    if (raw_data.length > Number(offset)) {
      // console.log("already_existing");
      let temp_raw_data_array = [];
      for (let i = offset; i < offset + 10; i++) {
        temp_raw_data_array.push(raw_data[i]);
      }
      set_raw_data_array(temp_raw_data_array);
    } else {
      // console.log("fetch next 10");
      axios
        .get(
          `https://web.dotconn.net/api/instagood/vending_machines/${props.id
          }/raw-data?fieldToSort=receivedTs&pageNumber=${Number(offset) / 10
          }&sizePerPage=10&sortOrder=DESC`
        )
        .then((response) => {
          let temp_array = [];
          let temp_raw_data_array = [];
          for (let i = 0; i < raw_data.length; i++) {
            temp_array.push(raw_data[i]);
          }
          for (let i = 0; i < response.data.content.length; i++) {
            temp_array.push(response.data.content[i]);
            temp_raw_data_array.push(response.data.content[i]);
          }
          dispatch(populate_raw_data(temp_array));
          set_raw_data_array(temp_raw_data_array);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  const onPressPrevious = () => {
    set_offset(Number(offset - 10));
    if (offset > 0) {
      let raw_data_temp_array = [];
      for (let i = offset - 10; i < offset; i++) {
        raw_data_temp_array.push(raw_data[i]);
        // console.log("The rawdataId is:", raw_data[i])
      }
      set_raw_data_array(raw_data_temp_array);
    } else {
      set_offset(0);
    }
  };
  const defaultCards = [
    {
      SNo: "1",
      RawDataid: "000546",
      ReceivedTS: "0056489752",
      GatewayID: "0056489752",
      RawMSG: "07/04/1927",
      Description: "16",
    },
    {
      SNo: "2",
      RawDataid: "000546",
      ReceivedTS: "0056489752",
      GatewayID: "0056489752",
      RawMSG: "07/04/1927",
      Description: "16",
    },

    {
      SNo: "3",
      RawDataid: "000546",
      ReceivedTS: "0056489752",
      GatewayID: "0056489752",
      RawMSG: "07/04/1927",
      Description: "16",
    },

    {
      SNo: "4",
      RawDataid: "000546",
      ReceivedTS: "0056489752",
      GatewayID: "0056489752",
      RawMSG: "07/04/1927",
      Description: "16",
    },

    {
      SNo: "5",
      RawDataid: "000546",
      ReceivedTS: "0056489752",
      GatewayID: "0056489752",
      RawMSG: "07/04/1927",
      Description: "16",
    },

    {
      SNo: "6",
      RawDataid: "000546",
      ReceivedTS: "0056489752",
      GatewayID: "0056489752",
      RawMSG: "07/04/1927",
      Description: "16",
    },

    {
      SNo: "7",
      RawDataid: "000546",
      ReceivedTS: "0056489752",
      GatewayID: "0056489752",
      RawMSG: "07/04/1927",
      Description: "16",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <>
      <Container>
        <TableContainer>
          {show_message === true ? (
            <div
              style={{
                width: "50%",
                height: "150px",
                backgroundColor: "#FFFFFF",
                marginLeft: "auto",
                right: "25%",
                left: "25%",
                top: "25%",
                position: "absolute",
                backgroundColor: "#F0F2F7",
                border: "1px solid #000000",
                borderRadius: "8px",
              }}
            >
              <img
                src={CloseIcon}
                alt="close Icon"
                style={{
                  width: "25px",
                  height: "25px",
                  objectFit: "contain",
                  float: "right",
                  position: "absolute",
                  right: "5px",
                }}
                onClick={() => set_show_message(false)}
              />
              <div
                style={{
                  width: "95%",
                  height: "60%",
                  alignSelf: "center",
                  marginRight: "auto",
                  marginLeft: "auto",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <label style={{ textAlign: "center" }}>Raw Msg</label>
                <label>Command: {command}</label>
                <label>Device ID:{device_id}</label>
                <label>Date:{date}</label>
                <label>Product Available:{product_available}</label>
              </div>
            </div>
          ) : null}
          <TableTop>
            <TableTitle style={{ width: "5%" }}>S. No</TableTitle>

            <TableTitle style={{ width: "30%" }}>Raw- Data id</TableTitle>

            <TableTitle style={{ width: "30%" }}>Raw Message</TableTitle>

            <TableTitle style={{ width: "30%" }}>Received Timestamp</TableTitle>

            {/* <TableTitle style={{ width: "20%" }}>Raw - MSG</TableTitle>

          <TableTitle style={{ width: "5%" }}>Description</TableTitle> */}
            <TableTitle style={{ width: "10%", textAlign: "center" }}>
              Actions
            </TableTitle>
          </TableTop>

          {raw_data_array.length > 0
            ? raw_data_array.map((card, i) => (
              <Column key={i}>
                <Card>
                  <TableDetails style={{ width: "5%" }}>
                    {Number(i + offset)}
                  </TableDetails>

                  <TableDetails style={{ width: "30%" }}>
                    {card.rawDataId}
                  </TableDetails>

                  <TableDetails style={{ width: "30%" }}>
                    {JSON.parse(card.rawMsg).command}
                  </TableDetails>

                  <TableDetails style={{ width: "30%" }}>
                    {/* {card.receivedTs} */}
                    {new Date(card.receivedTs).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }) + " (IST)"}
                  </TableDetails>

                  {/* <TableDetails style={{ width: "20%" }}>
                {card.RawMSG}
              </TableDetails>

              <TableDetails style={{ width: "5%" }}>
                {card.Description}
              </TableDetails> */}

                  <TableDetailsButton
                    style={{
                      width: "10%",
                      color: "#322A7D",
                      textAlign: "center",
                    }}
                    onClick={() => ShowFullMessage(card.rawMsg)}
                  >
                    Read More
                  </TableDetailsButton>
                </Card>
              </Column>
            ))
            : null}
          {raw_data_array.length > 0 ? (
            <div
              style={{
                display: "flex",
                width: "10%",
                height: "30px",
                flexDirection: "row",
                justifyContent: "center",
                border: "1px solid #FFFFFF",
                float: "right",
                marginRight: "2px",
              }}
            >
              <img
                style={{
                  width: "25px",
                  height: "25px",
                  objectFit: "contain",
                  alignSelf: "center",
                }}
                alt="previous icon"
                onClick={() => onPressPrevious()}
                src={PreviousIcon}
              />
              <label>{offset / 10}</label>
              <img
                style={{
                  width: "25px",
                  height: "25px",
                  objectFit: "contain",
                  alignSelf: "center",
                }}
                alt="next icon"
                src={NextIcon}
                onClick={() => onPressNext()}
              />
            </div>
          ) : null}
        </TableContainer>
      </Container>
    </>
  );
};

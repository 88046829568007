import { createSlice } from "@reduxjs/toolkit";
export const ZonesSlice = createSlice({
  name: "zones",
  initialState: {
    value: [],
  },
  reducers: {
    populate_zones: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_zones } = ZonesSlice.actions;
export default ZonesSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: 1,
};

export const AppSlice = createSlice({
  name: "AppComponent",
  initialState,
  reducers: {
    AppReducer: (state, action) => {
      switch (action.payload) {
        case 1:
          state.value=1;
          break;
        case 2:
          state.value = 2;
          break;
      }
    },
  },
});

export const { AppReducer } = AppSlice.actions;

export default AppSlice.reducer;

import React, { useEffect } from "react";
import tw from "twin.macro";
import { useSelector, useDispatch } from "react-redux";

import {BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import Sidebar from "../Components/Sidebar";
import Content from "../Components/Content";
import SignIn from "../Components/SignIn";
import { populate_user } from "../Components/Redux/UserSlice";
import axios from "axios";

const AppContainer = tw.div`relative bg-gray-200`;
const BodyContainer = tw.div`flex relative w-full`;

export default () => {
  const dispatch=useDispatch();
  useEffect(() => {
    //Call The User Details
    axios
      .get(`https://web.dotconn.net/auth/users/me`, {
        withCredentials: true,
      })
      .then((response1) => {
        console.log(response1.data.displayName);
        dispatch(
          populate_user(response1.data)
        );
        //dispatch(AppReducer(2));
      })
      .catch((error1) => {
        alert(error1.message);
      });
  }, []);
  
  const number = useSelector((state) => state.AppComponent.value);

  return (

<Router>
    <Routes>
    <Route path="/" element={<SignIn/> }/> 
    <Route path="/dashboard" element={
    <AppContainer>

      <BodyContainer className="AppBodyContainer">
        <Sidebar />
        <Content />
      </BodyContainer>

    </AppContainer>}/>
    </Routes>
</Router>

  );
};

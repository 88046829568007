import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { componentReducer } from "./Redux/ComponentSlice";
import { CardsReducer } from "./Redux/CardsSlice";

import { BsSearch } from "react-icons/bs";
import { GiVendingMachine } from "react-icons/gi";
import { MdOutlineSpaceDashboard } from "react-icons/md";

const ButtonText = tw.h1`pl-2 hidden md:block`;
const ButtonText1 = tw.h1`pl-2 hidden md:block `;

const IconContainer = tw.div`text-3xl`;
const SocialButtonsContainer = tw.div`bg-gray-800 w-3/12 md:w-2/12  overflow-auto z-10  flex flex-col  `;

const Heading = tw.h2`text-xl text-left font-semibold text-gray-200 ml-6 mt-20 mb-4`;
const SectionHeading = tw.h2`text-base md:text-4xl text-left font-semibold text-gray-200 ml-2 md:ml-6 mt-10 mb-4 cursor-pointer`;

const Link1 = styled.a`
  ${tw`w-64 font-semibold rounded-lg py-4 pl-4 text-xl text-gray-200 hocus:shadow-md  flex items-center transition-all duration-300 cursor-pointer focus:outline-none focus:shadow-outline ml-6 mr-8 mt-2 first:mt-0`}
`;

export default () => {
  const dispatch = useDispatch();
  const number = useSelector((state) => state.callComponent.value);

  console.log(number);

  const ZoneFn = () => {
    dispatch(componentReducer(2));

    dispatch(CardsReducer(1));
  };

  return (
    <>
      {number > 0 ? (
        <SocialButtonsContainer className="Sidebar">
          <SectionHeading onClick={() => dispatch(componentReducer(1))}>
            Dashboard
          </SectionHeading>

          <Heading>Main</Heading>
          <Link1
            style={{ backgroundColor: number === 1 ? "#3E459C" : null }}
            onClick={() => dispatch(componentReducer(1))}
          >
            <IconContainer>
              <MdOutlineSpaceDashboard />
            </IconContainer>
            <ButtonText1>Dashboard</ButtonText1>
          </Link1>

          <Link1
            style={{ backgroundColor: number === 2 ? "#3E459C" : null }}
            onClick={() => ZoneFn()}
          >
            <IconContainer>
              <BsSearch />
            </IconContainer>
            <ButtonText>Zone</ButtonText>
          </Link1>

          {/* <Heading>Vending</Heading>

          <Link1
            style={{ backgroundColor: number === 3 ? "#3E459C" : null }}
            onClick={() => dispatch(componentReducer(3))}
          >
            <IconContainer >
              <GiVendingMachine />
            </IconContainer>
            <ButtonText>Add Vending</ButtonText>
          </Link1> */}
        </SocialButtonsContainer>
      ) : null}
    </>
  );
};

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import SearchLocationPage from "../SearchLocationPage";
import AvialableMachine from "../AvialableMachine";
import MachineDetails from "../MachineDetails";
import { useSelector } from "react-redux";

const Container = tw.div`relative m-4 md:m-10 rounded-xl w-full`;
const Heading = tw.h2`text-3xl font-semibold text-gray-800 text-center mb-10  md:mx-56`;

export default () => {
  const number = useSelector((state) => state.CardsComponent.value);
  const vending_machine = useSelector(
    (state) => state.callVendingMachine.value
  );
  return (
    <Container>
      {number === "MachineOne" ? (
        <MachineDetails />
      ) : (
        <>
          <AvialableMachine props={vending_machine} />
        </>
      )}
    </Container>
  );
};

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: "default",
};

export const TableSlice = createSlice({
  name: "TableComponent",
  initialState,
  reducers: {
    TableReducer: (state, action) => {
      switch (action.payload) {
        case 0:
          state.value = "MachineDetails";
          break;
        case 1:
          state.value = "RawData";
          break;
        case 2:
          state.value = "NfcLogs";
          break;
        case 3:
          state.value = "VpiLogs";
          break;
        case 4:
          state.value = "EditVendingMachine";
          break;
      }
    },
  },
});

export const { TableReducer } = TableSlice.actions;

export default TableSlice.reducer;

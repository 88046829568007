import { createSlice } from "@reduxjs/toolkit";
export const VendingMachineSlice = createSlice({
  name: "vending_machine",
  initialState: {
    value: [],
  },
  reducers: {
    populate_vending_machine: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_vending_machine } = VendingMachineSlice.actions;
export default VendingMachineSlice.reducer;

import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import Planextlogo from "../Images/logo.png";

const Container = tw.div`bg-gray-200 items-center w-full justify-between flex py-4 md:px-20 px-6 `;

const HeaderLogoContainer = tw.div``;

export default () => {
  return (
    <Container>
      <a href="/">
        <HeaderLogoContainer>
          <img src={Planextlogo} width="100%" />
        </HeaderLogoContainer>
      </a>

      {/* <NavButton>
        <PrimaryButton href="/ContactUs">Sign In</PrimaryButton>
      </NavButton> */}
    </Container>
  );
};

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: 1,
};

export const CardsSlice = createSlice({
  name: "CardsComponent",
  initialState,
  reducers: {
    CardsReducer: (state, action) => {
      switch (action.payload) {
        case 1:
          state.value="Cards";
          break;
        case 2:
          state.value = "MachineOne";
          break;
      }
    },
  },
});

export const { CardsReducer } = CardsSlice.actions;

export default CardsSlice.reducer;

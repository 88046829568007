import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  value: {
    user: null,
  }
};

export const UserSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    populate_user: (state, action) => {
      state.value.user = action.payload;
    },
  },
});
export const {populate_user} = UserSlice.actions;

export default UserSlice.reducer;

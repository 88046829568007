import React from "react";
import tw from "twin.macro";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const Container = tw.div`relative flex  justify-center z-50`;


export default () => {
    
const items = [
    {
      id: 0,
      name: 'Andhra Pradesh'
    },
    {
      id: 1,
      name: 'Arunachal Pradesh'
    },
    {
      id: 2,
      name: 'Assam'
    },
    {
      id: 3,
      name: 'Bihar'
    },
    {
      id: 4,
      name: 'Chhattisgarh'
    },
    {
      id: 5,
      name: 'Goa'
    },
    {
      id: 6,
      name: 'Gujarat'
    },
    {
      id: 7,
      name: 'Haryana'
    },
    {
      id: 8,
      name: 'Himachal Pradesh'
    },
    {
      id: 9,
      name: 'Jharkhand'
    },
    {
      id: 10,
      name: 'Karnataka'
    },
    {
      id: 11,
      name: 'Kerala'
    },
    {
      id: 12,
      name: 'Madhya Pradesh'
    },
    {
      id: 13,
      name: 'Maharashtra'
    },
    {
      id: 14,
      name: 'Manipur'
    },
    {
      id: 15,
      name: 'Meghalaya'
    },
    {
      id: 16,
      name: 'Mizoram'
    },
    {
      id: 17,
      name: 'Nagaland'
    },
    {
      id: 18,
      name: 'Odisha'
    },
    {
      id: 19,
      name: 'Punjab'
    },
    {
      id: 20,
      name: 'Rajasthan'
    },
    {
      id: 21,
      name: 'Sikkim'
    },
    {
      id: 22,
      name: 'Tamil Nadu'
    },
    {
      id: 23,
      name: 'Telangana'
    },
    {
      id: 24,
      name: 'Tripura'
    },
    {
      id: 25,
      name: 'Uttrakhand'
    },
    {
      id: 26,
      name: 'Uttar Pradesh'
    },
    {
      id: 27,
      name: 'West Bengal'
    }
  ]

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    console.log(item)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  
  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left', zIndex:100 }}>{item.name}</span>
      </>
    )
  }

  return (
    <Container> 
        <div className="App">
    <header className="App-header">
      <div style={{ width: 300 }}>
      <ReactSearchAutocomplete
      styling={
       {
        searchIconMargin: '0 0 0 16px',
     height: "50px",
    }
      }showIcon={true}
          items={items}
          onSearch={handleOnSearch}
          onHover={handleOnHover}
          onSelect={handleOnSelect}
          onFocus={handleOnFocus}
          autoFocus
          formatResult={formatResult}
          placeholder="Search Keyword"
        />
      </div>
    </header>
  </div>
    </Container>
  );
};

import { store } from "./Components/Redux/Store";
import { Provider } from "react-redux";


import HomePage from "./Pages/HomePage";

export default function App() {


  return (
    <Provider store={store}>
      <HomePage/>
    </Provider>
  );
}


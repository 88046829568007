/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { useDispatch, useSelector } from "react-redux";
import { CardsReducer } from "./Redux/CardsSlice";
import { TableReducer } from "./Redux/TableSlice";
import { populate_vending_machine } from "./Redux/VendingMachineSlice";
import MachineDetails from "./MachineDetails";
import SearchLocationPage from "./SearchLocationPage";
import BackIcon from "../Images/Back.png";
import axios from "axios";
import { populate_raw_data } from "./Redux/RawDataSlice";
import { populate_nfc_log } from "./Redux/NFCLogSlice";
import { componentReducer } from "./Redux/ComponentSlice";
import Content from "./Content";
const Container = tw.div` flex justify-center items-center`;
const CardsContainer = tw.div` flex flex-wrap justify-center`;
// const Cards = tw.div`md:w-1.2/4 m-2 p-6 border border-gray-400 bg-white rounded-2xl overflow-hidden`;
const Cards = tw.div` m-2 border border-gray-400 bg-white rounded-lg overflow-hidden`;
const ImageContainer = tw.div`h-1/2 w-full `;
const DetailsContainer = tw.div`w-full  `;
const Detail = tw.div`my-2`;
const FlexContainer = tw.div`flex  w-full items-center justify-between `;

const Paragraph = tw.h5`text-base font-semibold   truncate  `;
const Heading = tw.h5`text-lg font-bold  truncate  `;
const SearchBarHeading = tw.h2`text-3xl font-semibold text-gray-800 text-center mb-10  md:mx-56`;
const Subheading = tw.h5`text-base font-semibold  `;

const ButtonContainer = tw.div`text-center border-t border-gray-400 mt-6 pt-2`;
const Button = tw.a`cursor-pointer text-xl`;
axios.defaults.withCredentials = true;

const ADD_ALLOWED_ROLES = ["SUPER_ADMIN","TENANT_OWNER","TENANT_ADMIN","ADMIN"];

export default ({ props }) => {
  const zones = useSelector((state) => state.callZone.value);
  const [show_machine_details, set_show_machine_details] = useState(false);
  const [selected_machine_name, set_selected_machine_name] = useState("");
  const [show_cards, set_show_cards] = useState(false);
  let vending_machine = useSelector(
    (state) => state.callVendingMachine.value
  );

  const dispatch = useDispatch();
  const user_details = useSelector((state) => state.callUser.value);

  const SeeMoreFn = (_machine_name) => {
    //  dispatch(CardsReducer(2));
    set_selected_machine_name(_machine_name);
    set_show_machine_details(true);
    //Get the Raw Data
    axios
      .get(
        `https://web.dotconn.net/api/instagood/vending_machines/${vending_machine.find((e) => e.displayName === _machine_name)
          .vendingMachineId
        }/raw-data?fieldToSort=receivedTs&pageNumber=0&sizePerPage=10&sortOrder=DESC`
      )
      .then((response) => {
        dispatch(populate_raw_data(response.data.content));
      })
      .catch((error) => {
        console.log(error.message);
      });
    //Get The NFC Log
    axios
      .get(
        `https://web.dotconn.net/api/instagood/vending_machines/transactions/nfc/${vending_machine.find((e) => e.displayName === _machine_name)
          .vendingMachineId
        }?fieldToSort=serverTs&pageNumber=0&sizePerPage=10&sortOrder=DESC`
      )
      .then((response) => {
        // console.log("NFC:" + Object.values(response.data.content));
        dispatch(
          populate_nfc_log(
            dispatch(populate_nfc_log(Object.values(response.data.content)))
          )
        );
      })
      .catch((error) => {
        console.log(error.message);
      });
    dispatch(TableReducer(0));
  };

  // const backBtnFuction = () => {
  //   set_show_machine_details(false)
  // }
  const [selected_name, set_selected_name] = useState('');
  const ChangeTheCompartment = (e) => {
    set_selected_name(e.target.name)
    set_show_cards(true)
    //Get the compartment
    axios
      .put(`https://web.dotconn.net/auth/change-compartment/${e.target.value}`)
      .then((response) => {
        axios
          .get(`https://web.dotconn.net/api/instagood/vending_machines`, {
            withCredentials: true,
          })
          .then((response1) => {
            dispatch(populate_vending_machine(response1.data));
          })
          .catch((error1) => {
            alert(error1.meessage);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {show_machine_details === false ? (
        <>
          <SearchBarHeading>
            Choose From List Of Available Zone For The User
          </SearchBarHeading>
          {/* <SearchLocationPage /> */}
          <div
            style={{
              width: "82%",
              height: "80px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <select
              style={{
                width: "65.5%",
                height: "72px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #000000",
                borderRadius: "8px",
                paddingHorizontal: "20px",
                paddingInline: "20px",
                alignSelf: "center",
                color: "black",
                fontSize: 20,
                fontWeight: "bold",
                cursor: "pointer",
              }}

              onChange={(e) => ChangeTheCompartment(e)}
            >
              <option
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                  color: "black",
                  fontSize: 20,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}

              >
                Select from the following machines....
              </option>
              {zones.map((item, index) => (
                <option
                  style={{
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    color: "black",
                    fontSize: 20,
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  key={index}
                  value={item.id}
                  name={item.displayName}
                >
                  {item.displayName}
                </option>
              ))}
            </select>
            {ADD_ALLOWED_ROLES.includes(user_details.user?.userEntityRole) &&
            <button
              style={{
                width: "30%",
                height: "72px",
                backgroundColor: "#322A7D",
                borderRadius: "8px",
                border: "1px solid #000000",
                fontSize: 20,
                fontWeight: "bold",
                color: "#FFFFFF",
              }}
              onClick={() => dispatch(componentReducer(3))}
            >
              Add Vending Machine
            </button>}
          </div>
          {show_cards === true ?
            <Container>
              <CardsContainer>
                {props.map((item, index) => (
                  <Cards key={index} style={{ width: "26.3%" }}>
                    <FlexContainer >
                      <DetailsContainer>
                        <div style={{ backgroundColor: '#322A7D', padding: "1.5rem", color: "#ffffff", textAlign: "center", fontWeight: "bold" }}>
                          <Detail style={{}}>{item.displayName}</Detail>
                        </div>
                        <div style={{ padding: ".5rem 1.5rem 1.5rem  1.5rem", textAlign: "center" }}>
                          {/* <hr style={{ borderColor: "rgba(156, 163, 175" }} /> */}
                          {/* <Detail>
                            <Heading style={{ fontSize: "1rem" }}>Machine Name:{item.displayName}</Heading>
                          </Detail> */}


                          {/* <Detail>
                  <Subheading>Location </Subheading>
                  <Heading>Area Name</Heading>
                </Detail> */}

                          <FlexContainer>
                            {/* <Detail>
                    <Subheading>Machine Status </Subheading>
                    <Heading>25</Heading>
                  </Detail> */}
                            {/* <Detail>
                    <Subheading>Machine Status </Subheading>
                    <Heading>25</Heading>
                  </Detail> */}
                          </FlexContainer>

                          <Detail>
                            <Subheading>Product Available: </Subheading>
                            <span style={{ fontWeight: "bold" }}>{item.productsAvailable}</span>

                          </Detail>
                          <Detail>
                            <Subheading>Last Communicated Timestamp:</Subheading>
                            <Heading style={{ fontSize: "1rem", marginBottom: "1.5rem" }}>

                              {
                                new Date(item.lastCommunicatedTs).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }) + " (IST)"
                              }
                            </Heading>
                          </Detail>

                          <Button style={{ backgroundColor: "#3E459C", padding: "8px 12px", color: "#ffffff", borderRadius: "5px", fontSize: "1rem" }} onClick={() => SeeMoreFn(item.displayName)}>
                            See More
                          </Button>

                          {/* <ButtonContainer onClick={() => console.log("Hello")}>
                            <Button style={{ backgroundColor: "#3E459C", padding: "8px 12px", color: "#ffffff", borderRadius: "5px", fontSize: "1rem" }} onClick={() => SeeMoreFn(item.displayName)}>
                              See More
                            </Button>
                          </ButtonContainer> */}
                        </div>
                      </DetailsContainer>
                    </FlexContainer>
                  </Cards>
                ))}
              </CardsContainer>
            </Container>
            : (<h1 style={{ textAlign: "center", padding: "50px 0", fontWeight: "bold" }}>Nothing selected</h1>)}
        </>
      ) : (
        <>
          <img
            style={{
              width: "40px",
              height: "40px",
              objectFit: "contain",
              //marginLeft: "-20px",
              top: "-60px",
              alignSelf: "flex-start",
              position: "absolute",
              cursor: "pointer",
            }}
            alt="back"
            src={BackIcon}
            // onClick={backBtnFuction()}
            onClick={() => {
              set_show_machine_details(false)
              // console.log(props, vending_machine)
              set_show_cards(false)
              // <Content/>
            }}
          />
          <MachineDetails props={{ displayName: selected_machine_name }} />
        </>
      )}
    </>
  );
};

import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";

const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.a`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.a`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const FormContainer = styled.div`
  ${tw` bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-textprimary text-base font-medium tracking-wide border-b-2 py-2 text-textprimary hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;

const Link1 = tw.a`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default ({ cards = null }) => {
  const defaultCards = [
    {
      vendorId: "ser1",
      vendorName: "Divyam",
      number: "+9112456789",
      email: "email@gmail.com",
      category: "Hygiene",
      serviceName: "House Cleaning",
      minimumServiceCharge: "2000",
      JoinDate: "2022-08-28",
      Status: "Pending",
    },
    {
      vendorId: "ser1",
      vendorName: "Dishant",
      number: "+9112456789",
      email: "email@gmail.com",
      category: "Plumbing",
      serviceName: "House Sanitization",
      minimumServiceCharge: "2500",
      JoinDate: "2022-08-28",
      Status: "Pending",
    },

    {
      vendorId: "ser1",
      vendorName: "User",
      number: "+9112456789",
      email: "email@gmail.com",
      category: "IT",
      serviceName: "Website Development",
      minimumServiceCharge: "10000",
      JoinDate: "2022-08-28",
      Status: "Pending",
    },

    {
      vendorId: "ser1",
      vendorName: "Guest",
      number: "+9112456789",
      email: "email@gmail.com",
      category: "Hygiene",
      serviceName: "Hygiene",
      minimumServiceCharge: "7500",
      JoinDate: "2022-08-28",
      Status: "Pending",
    },

    {
      vendorId: "ser1",
      vendorName: "Vendor Name",
      number: "+9112456789",
      email: "email@gmail.com",
      category: "Plumbing",
      serviceName: "Bathroom Plumbing",
      minimumServiceCharge: "500",
      JoinDate: "2022-08-28",
      Status: "Pending",
    },
  ];

  if (!cards) cards = defaultCards;

  const [show_upload_modal, set_show_upload_modal] = useState(false);

  return (
    <Container>
      {show_upload_modal === true ? (
        <FormContainer>
          <form action="#">
            <TwoColumn>
              <ColumnForm>
                <InputContainer>
                  <Label htmlFor="name-input">Full Name</Label>
                  <Input
                    id="full-name"
                    type="text"
                    name="fullname"
                    placeholder="Vendor Name"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "Vendor Name")}
                  />
                </InputContainer>

                <InputContainer>
                  <Label htmlFor="name-input">Company Name</Label>
                  <Input
                    id="company-name"
                    type="text"
                    name="companyname"
                    placeholder="Illusion Spotline"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "Illusion Spotline")}
                  />
                </InputContainer>

                <InputContainer>
                  <Label htmlFor="name-input">Phone Number</Label>
                  <Input
                    id="phonenumber"
                    type="text"
                    name="phonenumber"
                    placeholder="123456789"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "123456789")}
                  />
                </InputContainer>

                <InputContainer>
                  <Label htmlFor="name-input">Category</Label>
                  <Input
                    id="category"
                    type="text"
                    name="category"
                    placeholder="Hygiene, Plumbing, etc."
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) =>
                      (e.target.placeholder = "Hygiene, Plumbing, etc.")
                    }
                  />
                </InputContainer>
                <InputContainer>
                  <Label htmlFor="name-input">Minimum Service Charge</Label>
                  <Input
                    id="minimum-service-charge"
                    type="text"
                    name="minimumservicecharge"
                    placeholder="500"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "500")}
                  />
                </InputContainer>
              </ColumnForm>

              <ColumnForm>
                <InputContainer>
                  <Label htmlFor="name-input">Username</Label>
                  <Input
                    id="username"
                    type="text"
                    name="username"
                    placeholder="VendorUsername"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "VendorUsername")}
                  />
                </InputContainer>

                <InputContainer>
                  <Label htmlFor="name-input">Email</Label>
                  <Input
                    id="email"
                    type="text"
                    name="email"
                    placeholder="contact@isl.com"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "contact@isl.com")}
                  />
                </InputContainer>

                <InputContainer>
                  <Label htmlFor="name-input">Service</Label>
                  <Input
                    id="tagline"
                    type="text"
                    name="Tagline"
                    placeholder="Plumber, House Cleaning, etc."
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) =>
                      (e.target.placeholder = "Plumber, House Cleaning, etc.")
                    }
                  />
                </InputContainer>

                <InputContainer>
                  <Label htmlFor="name-input">Tagline</Label>
                  <Input
                    id="tagline"
                    type="text"
                    name="Tagline"
                    placeholder="Web/Application Development"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) =>
                      (e.target.placeholder = "Web/Application Development")
                    }
                  />
                </InputContainer>
              </ColumnForm>
            </TwoColumn>

            <InputContainer>
              <Label htmlFor="name-input">Description</Label>
              <InputLarge
                id="description"
                type="text"
                name="description"
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sed suscipit mi. Maecenas hendrerit purus ut auctor posuere. Mauris a est justo. Maecenas ac lorem vitae erat suscipit tincidunt id blandit felis. Donec mattis purus nunc, pretium fermentum nulla eleifend eu. Donec quis massa libero. Nulla tincidunt eget sapien ut sagittis."
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) =>
                  (e.target.placeholder =
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sed suscipit mi. Maecenas hendrerit purus ut auctor posuere. Mauris a est justo. Maecenas ac lorem vitae erat suscipit tincidunt id blandit felis. Donec mattis purus nunc, pretium fermentum nulla eleifend eu. Donec quis massa libero. Nulla tincidunt eget sapien ut sagittis.")
                }
              />
            </InputContainer>

            <Actions>
              <Link1 href="/Login">Save Category</Link1>
            </Actions>
          </form>
        </FormContainer>
      ) : null}

      <h1 style={{ color: "white", textAlign: "center", fontSize: 32 }}>
        VENDORS TABLE{" "}
      </h1>
      <TableContainer>
        <TableTop>
          <TableTitle style={{ width: "6%" }}>Vendor ID</TableTitle>

          <TableTitle style={{ width: "10%" }}>Vendor Name</TableTitle>

          <TableTitle style={{ width: "10%" }}>Number</TableTitle>

          <TableTitle style={{ width: "12%" }}>Email</TableTitle>

          <TableTitle style={{ width: "12%" }}>Category</TableTitle>

          <TableTitle style={{ width: "12%" }}>Service</TableTitle>

          <TableTitle style={{ width: "8%" }}>
            Minimum Service Charge
          </TableTitle>

          <TableTitle style={{ width: "10%" }}>Status</TableTitle>

          <TableTitle style={{ width: "10%" }}>Join Date</TableTitle>

          <TableTitle style={{ width: "10%" }}>Actions</TableTitle>
        </TableTop>

        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <TableDetails style={{ width: "6%" }}>
                {card.vendorId}
              </TableDetails>

              <TableDetails style={{ width: "10%" }}>
                {card.vendorName}
              </TableDetails>

              <TableDetails style={{ width: "10%" }}>
                {card.number}
              </TableDetails>

              <TableDetails style={{ width: "12%" }}>{card.email}</TableDetails>

              <TableDetails style={{ width: "12%" }}>
                {card.category}
              </TableDetails>

              <TableDetails style={{ width: "12%" }}>
                {card.serviceName}
              </TableDetails>

              <TableDetails style={{ width: "8%" }}>
                {card.minimumServiceCharge}
              </TableDetails>

              <TableDetails style={{ width: "10%" }}>
                {card.JoinDate}
              </TableDetails>

              <TableDetails style={{ width: "10%" }}>
                {card.Status}
              </TableDetails>

              <TableDetails style={{ width: "10%" }}>
                <ActionIconsColumn>
                  <IconContainer>
                    <AiOutlineEdit />
                  </IconContainer>

                  <IconContainer>
                    <AiOutlineDelete />
                  </IconContainer>
                </ActionIconsColumn>
              </TableDetails>
            </Card>
          </Column>
        ))}
        <ButtonsContainer>
          <Button href="/Login">
            <AiOutlineArrowLeft />
            <ButtonText>Previous</ButtonText>
          </Button>
          <Button
            onClick={() => set_show_upload_modal(true)}
            style={{ backgroundColor: "#131451" }}
          >
            Add New Vendor
          </Button>

          <Button href="/Login">
            <ButtonText>Next</ButtonText>
            <AiOutlineArrowRight />
          </Button>
        </ButtonsContainer>
      </TableContainer>
    </Container>
  );
};

import { createSlice } from "@reduxjs/toolkit";
export const NFCLogSlice = createSlice({
  name: "nfc_log",
  initialState: {
    value: [],
  },
  reducers: {
    populate_nfc_log: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_nfc_log } = NFCLogSlice.actions;
export default NFCLogSlice.reducer;

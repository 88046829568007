/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { TableReducer } from "./Redux/TableSlice";
import RawData from "./ContentPage/RawData";
import NfcLog from "./ContentPage/NfcLog";
import VpiLog from "./ContentPage/VpiLog";
import SignoutIcon from "../Images/signout.png";
import EditIcon from "../Images/Edit.png";
import axios from "axios";
import { populate_vending_machine } from "./Redux/VendingMachineSlice";
import { componentReducer } from "./Redux/ComponentSlice";
const InputContainer = tw.div`relative flex items-center py-5 w-full`;
const Label = tw.div`text-gray-900 tracking-wide font-semibold   text-base md:text-2xl w-4/12 md:w-3/12`;
const Input = tw.input`p-2 md:p-4 rounded-lg bg-gray-200 w-6/12 md:w-6/12`;
const ActionsMain = tw.div`flex flex-col sm:flex-row justify-center mt-8  lg:justify-center `;
const Link2 = tw.text`bg-blue-200 text-blue-900 mx-4 my-2 px-6 py-2 rounded-lg  text-base md:text-2xl font-semibold`;

const HeaderTop = tw.div`flex flex-row items-center justify-center bg-white border-b-2 text-center py-2 `;
const HeaderTitle = tw.div`text-sm md:text-xl font-bold relative py-3 mx-4  md:mx-0 text-gray-500 cursor-pointer`;

const Container = tw.div``;
const Content = tw.div` p-4 md:p-14 bg-white`;
const FormContainer = tw.div` bg-white  md:mx-16 rounded-xl p-4 md:p-8 mb-8`;
const DetailsContainer = tw.div`w-full  `;
const Detail = tw.div`my-2`;

const Heading = tw.h5`text-xl font-semibold  truncate  `;
const Subheading = tw.h5`text-base  truncate text-gray-500 `;

const EDIT_ALLOWED_ROLES = ["SUPER_ADMIN","TENANT_OWNER","TENANT_ADMIN","ADMIN"];

export default ({ props }) => {
  const dispatch = useDispatch();
  const user_details = useSelector((state) => state.callUser.value);
  const vending_machines = useSelector(
    (state) => state.callVendingMachine.value
  );
  const raw_data = useSelector((state) => state.callRawData.value);

  const nfc_log = useSelector((state) => state.callNFCLog.value);

  const number = useSelector((state) => state.TableComponent.value);
  const [name, set_name] = useState("");
  const onChangeName = (e) => {
    set_name(e.target.value);
  };
  const [qr_id, set_qr_id] = useState("");
  const onChangeQrId = (e) => {
    set_qr_id(e.target.value);
  };
  const [product_price, set_product_price] = useState(0);
  const onChangeProductPrice = (e) => {
    set_product_price(e.target.value);
  };
  useEffect(() => {
    set_name(props.displayName);
    set_qr_id(
      vending_machines.find((e) => e.displayName === props.displayName)
        .razorpayQrCodeId
    );
    set_product_price(
      vending_machines.find((e) => e.displayName === props.displayName)
        .productPrice
    );
  }, []);
  const UpdateVendingMachine = () => {
    const data =
    //JSON.stringify(
    {
      displayName: name,
      productPrice: product_price,
      razorpayQrCodeId: qr_id,
    }
    //);
    let vending_machine_temp = [];
    for (let i = 0; i < vending_machines.length; i++) {
      vending_machine_temp.push(vending_machines[i]);
    }
    let _vending_id = vending_machine_temp.find(
      (e) => e.displayName === props.displayName
    ).vendingMachineId;
    // console.log(name + "-" + qr_id + "-" + product_price + "-" + _vending_id);
    axios
      .put(
        `https://web.dotconn.net/api/instagood/vending_machines/${_vending_id}`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        //  vending_machine_temp.find(e=>e.vendingMachineId).displayName="Trst Of"
        // vending_machine_temp[vending_machine_temp.findIndex(
        //   (e) => e.vendingMachineId === _vending_id
        // )].displayName = name;
        // vending_machine_temp[vending_machine_temp.findIndex(
        //   (e) => e.vendingMachineId === _vending_id
        // )].razorpayQrCodeId = qr_id;
        // vending_machine_temp[vending_machine_temp.find(
        //   (e) => e.vendingMachineId === _vending_id
        // )].productPrice = product_price;
        dispatch(populate_vending_machine(vending_machine_temp));
        dispatch(componentReducer(2));
        alert("Successfully updated the vending machine!!");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        alert("Error:" + error.message);
      });
  };
  const ResetVendingMachine = () => {
    set_name("");
    set_qr_id("");
    set_product_price(0);
  };
  return (
    <Container>
      <HeaderTop>
        <HeaderTitle
          style={{
            width: "15%",
            color: number === "MachineDetails" ? "black" : null,
          }}
          onClick={() => dispatch(TableReducer(0))}
        >
          MACHINE DETAILS
        </HeaderTitle>
        <HeaderTitle
          style={{ width: "15%", color: number === "RawData" ? "black" : null }}
          onClick={() => dispatch(TableReducer(1))}
        >
          RAW DATA
        </HeaderTitle>
        <HeaderTitle
          style={{ width: "15%", color: number === "NfcLogs" ? "black" : null }}
          onClick={() => dispatch(TableReducer(2))}
        >
          NFC LOGS
        </HeaderTitle>

        {/* <HeaderTitle
          style={{ width: "15%", color: number === "VpiLogs" ? "black" : null }}
          onClick={() => dispatch(TableReducer(3))}
        >
          VPI LOGS
        </HeaderTitle> */}
        <HeaderTitle style={{ width: "55%" }}></HeaderTitle>
        {EDIT_ALLOWED_ROLES.includes(user_details.user?.userEntityRole) &&
        <img
          src={EditIcon}
          alt="edit"
          style={{
            marginRight: "20px",
            width: "8%",
            color: number === "EditVendingMachine" ? "black" : null,
            fontWeight: "bold",
            borderRadius: 1000,
            border: "2px solid #000000",
            cursor: "pointer"
          }}
          onClick={() => dispatch(TableReducer(4))}
        ></img>
        }
      </HeaderTop>

      {number === "MachineDetails" ? (
        <Content>
          <DetailsContainer>
            <Detail>
              <Heading>
                Vending Machine ID:
                {
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).vendingMachineId
                }
              </Heading>
            </Detail>
            <Detail>
              <Heading>Machine Name:{props.displayName}</Heading>
            </Detail>
            <Detail>
              <Heading>
                Razorpay QR Code ID:
                {
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).razorpayQrCodeId
                }
              </Heading>
            </Detail>
            <Detail>
              <Heading>
                Product Price:
                {
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).productPrice
                }
              </Heading>
            </Detail>
            <Detail>
              <Heading>
                MQTT Port:
                {
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).mqttPort
                }
              </Heading>
            </Detail>
            <Detail>
              <Heading>
                MQTT Host:
                {
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).mqttHost
                }
              </Heading>
            </Detail>
            <Detail>
              <Heading>
                MQTT Topic To Publish:
                {
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).mqttTopicToPublish
                }
              </Heading>
            </Detail>
            <Detail>
              <Heading>
                MQTT Topic To Subscribe:
                {
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).mqttTopicToSubscribe
                }
              </Heading>
            </Detail>
            <Detail>
              <Heading>
                Last Communicated Command:
                {
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).lastCommunicatedCommand
                }
              </Heading>
            </Detail>
            <Detail>
              <Heading>
                Product Available:{" "}
                {
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).productsAvailable
                }
              </Heading>
            </Detail>
            <Detail>
              <Heading>
                Last Communicated Timestamp:
                {
                  new Date(vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).lastCommunicatedTs).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }) + " (IST)"
                }
                {/* {new Date(
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).lastCommunicatedTs
                ).getFullYear()}
                -
                {new Date(
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).lastCommunicatedTs
                ).getMonth()}
                -
                {new Date(
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).lastCommunicatedTs
                ).getDate()}{" "}
                {new Date(
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).lastCommunicatedTs
                ).getHours()}
                :
                {new Date(
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).lastCommunicatedTs
                ).getMinutes()}
                :
                {new Date(
                  vending_machines.find(
                    (e) => e.displayName === props.displayName
                  ).lastCommunicatedTs
                ).getSeconds()} */}
              </Heading>
            </Detail>
          </DetailsContainer>
        </Content>
      ) : null}
      {number === "EditVendingMachine" ? (
        <FormContainer>
          <Heading>UPDATE VENDING MACHINE</Heading>
          <form action="#">
            <InputContainer>
              <Label htmlFor="name-input">Vending Machine</Label>
              <Input

                type="text"

                value={name}
                onChange={(e) => onChangeName(e)}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="name-input">QR Code Id</Label>

              <Input

                type="text"

                value={qr_id}
                onChange={(e) => onChangeQrId(e)}
              />
              {/* <FormButton href="#">Add</FormButton> */}
            </InputContainer>
            <InputContainer>
              <Label htmlFor="name-input">Product Price</Label>

              <Input

                type="text"
                pattern="[0-9]*"

                value={product_price}
                onChange={(e) => onChangeProductPrice(e)}
              />
              {/* <FormButton href="#">Add</FormButton> */}
            </InputContainer>
            <ActionsMain>
              <Link2 onClick={() => UpdateVendingMachine()}>Update</Link2>
              {/* <Link2
                //href="#"
                onClick={() => ResetVendingMachine()}
              >
                Reset
              </Link2> */}
            </ActionsMain>
          </form>
        </FormContainer>
      ) : null}

      {number === "RawData" ? <RawData props={{
        id: vending_machines.find(
          (e) => e.displayName === props.displayName
        ).vendingMachineId
      }} /> : null}
      {number === "NfcLogs" ? <NfcLog /> : null}
      {number === "VpiLogs" ? <VpiLog /> : null}
    </Container>
  );
};

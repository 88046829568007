import React, { useState } from "react";
import tw from "twin.macro";
const Container = tw.div`relative w-full my-10`;

const TableContainer = tw.div`  bg-white  items-center md:items-stretch  md:justify-center overflow-hidden border-2 border-blue-300 rounded-xl  `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center  text-center py-2 border-solid border-b  border-blue-300  `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-6 border-solid border-b   border-blue-300`;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;


export default ({ cards = null }) => {
  const defaultCards = [
    {


      SNo: "1",
      RawDataid: "000546",
      ReceivedTS: "0056489752",
      GatewayID: "0056489752",
      RawMSG: "07/04/1927",
      Description: "16",

    },
    {
      SNo: "2",
      RawDataid: "000546",
      ReceivedTS: "0056489752",
      GatewayID: "0056489752",
      RawMSG: "07/04/1927",
      Description: "16",
    },

    {
      SNo: "3",
      RawDataid: "000546",
      ReceivedTS: "0056489752",
      GatewayID: "0056489752",
      RawMSG: "07/04/1927",
      Description: "16",
    },

    {
      SNo: "4",
      RawDataid: "000546",
      ReceivedTS: "0056489752",
      GatewayID: "0056489752",
      RawMSG: "07/04/1927",
      Description: "16",
    },

    {
      SNo: "5",
      RawDataid: "000546",
      ReceivedTS: "0056489752",
      GatewayID: "0056489752",
      RawMSG: "07/04/1927",
      Description: "16",
    },

    {
      SNo: "6",
      RawDataid: "000546",
      ReceivedTS: "0056489752",
      GatewayID: "0056489752",
      RawMSG: "07/04/1927",
      Description: "16",
    },

    {
      SNo: "7",
      RawDataid: "000546",
      ReceivedTS: "0056489752",
      GatewayID: "0056489752",
      RawMSG: "07/04/1927",
      Description: "16",
    },
  ];

  const [show_upload_modal, set_show_upload_modal] = useState(false);

  if (!cards) cards = defaultCards;

  return (
    <Container>


      <TableContainer>
        <TableTop>
          <TableTitle style={{ width: "5%" }}>S. No</TableTitle>

          <TableTitle style={{ width: "20%" }}>Log - ID</TableTitle>

          <TableTitle style={{ width: "20%" }}>NFC ID</TableTitle>

          <TableTitle style={{ width: "20%" }}>Vending Machine</TableTitle>

          <TableTitle style={{ width: "20%" }}>Servers TS</TableTitle>

          <TableTitle style={{ width: "5%" }}>Payload</TableTitle>
          <TableTitle style={{ width: "10%" }}></TableTitle>
        </TableTop>

        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <TableDetails style={{ width: "5%" }}>
                {card.SNo}
              </TableDetails>

              <TableDetails style={{ width: "20%" }}>
                {card.RawDataid}
              </TableDetails>

              <TableDetails style={{ width: "20%" }}>
                {card.ReceivedTS}
              </TableDetails>

              <TableDetails style={{ width: "20%" }}>
                {card.GatewayID}
              </TableDetails>

              <TableDetails style={{ width: "20%" }}>
                {card.RawMSG}
              </TableDetails>

              <TableDetails style={{ width: "5%" }}>
                {card.Description}
              </TableDetails>

<TableDetails style={{ width: "10%" }}>
</TableDetails>
            </Card>
          </Column>
        ))}
      </TableContainer>
    </Container>
  );
};
